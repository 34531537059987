import classNames from 'classnames';
import { graphql } from 'gatsby';
import React from 'react';
import BodyText from '../components/body-text/BodyText';
import Layout from '../components/layout/Layout';
import * as styles from '../pages/service/service.module.scss';
import { ServiceNodeFrontmatter } from '../types/servicesTypes';

type ServiceContainerProps = {
  data: {
    markdownRemark: {
      html: string
      frontmatter: ServiceNodeFrontmatter
    }
  }
}

type ServicePageTemplateProps = {
  service: {
    html: string
    frontmatter: ServiceNodeFrontmatter
  }
}

export const ServicePageTemplate: React.FC<ServicePageTemplateProps> = ({ service }) => {
  return (
    <main className={styles.servicePage}>
      <div className={classNames(classNames, styles.servicePageContainer, 'wrapper')}>
        <h1 className={styles.servicePageTitle}>{service.frontmatter.title}</h1>
        <BodyText className={styles.servicePageContent} html={service.html} />
      </div>
    </main>
  );
};

const ServiceContainer: React.FC<ServiceContainerProps> = ({ data }) => {
  const { markdownRemark: service } = data;

  return (
    <Layout>
      <ServicePageTemplate service={service} />
    </Layout>

  );
};

export default ServiceContainer;

export const serviceByIdQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        path
        icon
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`

